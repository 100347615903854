import { PropsWithChildren } from 'react'
import localFont from 'next/font/local'
import StaticImg from '@/components/common/StaticImg'
import { ComponentUtils } from '@/utils/design-system/componentUtils'

const pretendardFont = localFont({
  src: '../../../public/fonts/PretendardVariable.woff2',
  preload: true,
  display: 'swap'
})

const Layout = ({ children }: PropsWithChildren) => {
  return (
    <div
      className={ComponentUtils.cn(
        'flex h-full w-screen items-center justify-center gap-120',
        pretendardFont.className
      )}>
      <div className="fixed top-0 z-[-10] h-screen w-screen bg-white min-[420px]:bg-primary-50" />
      <div className="flex w-body max-lg:hidden">
        <aside className="fixed top-[12%] flex h-[80vh] w-full max-w-body flex-col items-center justify-around">
          <StaticImg className="mx-auto h-29 w-173" src="/images/string-logo.svg" alt="neublity-logo" />
          <div className="flex flex-col items-center justify-center">
            <span className="headline5 font-bold">배달비 걱정 없는</span>
            <span className="headline5 font-bold">뉴비 오더로 주문하세요</span>
          </div>
          <StaticImg className="mx-auto flex h-280 w-193" src="/images/side-logo.png" alt="neublity-side-logo" />
        </aside>
      </div>
      {children}
    </div>
  )
}

export default Layout

import React from 'react'

const RootMetaTags = () => {
  return (
    <>
      <title>뉴비오더</title>
      <link rel="icon" type="image/png" sizes="196x196" href="assets/favicon-196.png" />
      <link rel="apple-touch-icon" sizes="180x180" href="assets/apple-icon-180.png" />
      <meta name="application-name" content="Neubie-Order" />
      <meta name="viewport" content="initial-scale=1, viewport-fit=cover, user-scalable=no" />
      <meta name="description" content="뉴비와 함께하는 우리동네 배달앱 뉴비오더" />
      <meta name="format-detection" content="telephone=no, date=no, email=no, address=no" />
    </>
  )
}

export default RootMetaTags
